<template>
  <div class="world calculator-page">
    <div class="wrapper-title">
      <div class="container">
        <button class="text-wilmsLightBlue font-100 block" @click="backToOverview">&larr; {{ $t('Terug naar offerte') }}</button>
        <h1 v-if="quoteNumber">{{ $t('Offerte') + ' ' + quoteNumber }}: {{ $t('Configuratie') }} {{ selectedOptions.name === '' || !selectedOptions.name ? $t('screen') : selectedOptions.name }}</h1>
        <div class="flex"></div>
      </div>
    </div>

    <div class="calculator-wrapper">
      <div class="container">
        <div class="steps-wrapper">
          <div class="step-buttons">
            <h3>{{ $t('Configuratie') }}</h3>
            <CalculatorButton :activeStep="activeStep" :label=" $t('Naam') " :type="'name'" @setStep="setStep"/>
            <CalculatorButton :activeStep="activeStep" :disabled="!(validateName())" :label=" $t('Type') " :type="'type'" @setStep="setStep"/>
            <CalculatorButton :activeStep="activeStep" :disabled="!(validateType())" :label=" $t('Motorisatie') " :type="'motor'" @setStep="setStep"/>
            <CalculatorButton :activeStep="activeStep" :disabled="!(validateMotor())" :label=" $t('Breedte en hoogte') " :type="'size'" @setStep="setStep"/>
            <CalculatorButton :activeStep="activeStep" :disabled="!(validateWidth())" :label=" $t('Doeksoort') " :type="'fabric_types'" @setStep="setStep"/>
            <CalculatorButton :activeStep="activeStep" :disabled="!(validateFabricType())" :label=" $t('Kleur kast') " :type="'cabinet_colors'" @setStep="setStep"/>
            <CalculatorButton :activeStep="activeStep" :disabled="!(validateColors())" :label=" $t('Doek kleur') " :type="'fabric_colors'" @setStep="setStep"/>
            <CalculatorButton :activeStep="activeStep" :disabled="!(validateFabricColor())" :label=" $t('Opties') " :type="'options'" @setStep="setStep"/>
          </div>

          <div class="step-contents">
            <div v-if="activeStep === 'name'" :class="activeStep">
              <h2>{{ $t('Geef een naam aan je screen') }}</h2>

              <formulate-form>
                <formulate-input ref="name" v-model="selectedOptions.name" :label="$t('Naam')"></formulate-input>

                <div class="buttons">
                  <button :disabled="selectedOptions.name === '' || !selectedOptions.name" class="btn" @click="setStep('type')">{{ $t('Volgende') }}</button>
                </div>
              </formulate-form>
            </div>

            <div v-if="activeStep === 'type'">
              <h2>{{ $t('Naar welk type screen ben je op zoek?') }}</h2>

              <div class="types-wrapper">
                <div class="types types-shutter types-screen">
                  <button v-for="type in types" :key="type.key" :class="{'active': selectedOptions.type === type.key }" class="type" @click="selectType(type.key)" @dblclick="setStep('motor')">
                    <img v-if="selectedOptions.type === type.key" alt="" class="icon-check" src="@/assets/img/check_solid.svg" width="23">
                    <div class="image">
                      <img :alt="type.label" :src="type.image">
                    </div>
                    <h3>{{ type.label }}</h3>
                  </button>
                </div>
              </div>

              <div class="buttons">
                <button :disabled="!selectedOptions.type" class="btn" @click="setStep('motor')">{{ $t('Volgende') }}</button>
              </div>
            </div>

            <div v-if="activeStep === 'motor'">
              <h2>{{ $t('Selecteer het type motor') }}</h2>

              <formulate-input v-model="selectedOptions.engineConnection" :label="$t('Motor')" :options="enginesConnections" :placeholder="$t('Selecteer type motor')" type="select" @change="setEngine"></formulate-input>
              <div class="buttons">
                <button :disabled="!(validateMotor())" class="btn" @click="setStep('size')">{{ $t('Volgende') }}</button>
              </div>
            </div>

            <div v-if="activeStep === 'size'">
              <h2>{{ $t('Selecteer jouw breedte en hoogte') }}</h2>
              <formulate-form ref="widthForm" name="widthForm">

                <div v-if="selectedOptions.width && !maxHeight" class="error-message">
                  <span v-if="selectedOptions.measurement_style === 'outside'">
                    {{ $t('Let op: opgegeven breedte is te breed. Hou rekening met de geleiders (+ 110mm).') }}
                  </span> <span v-else>
                    {{ $t('Let op: opgegeven breedte is te breed.') }}
                  </span>
                </div>

                <div v-if="selectedOptions.height && !price" class="error-message">
                  {{ $t('Er kon geen prijs gevonden worden voor de opgegeven breedte en hoogte.') }}
                </div>

                <formulate-input @input="setWidth" type="radio" :options="{'default': $t('Standaard'), 'cabinet': $t('Kast'), 'cabinet_rails': $t('Kast + geleiders')}" v-model="selectedOptions.measurement_style"></formulate-input>

                <formulate-input
                  v-model="selectedOptions.width"
                  :help="$t('De minimale breedte is ') + minWidth + 'mm. ' + $t('De maximale breedte is ') + maxWidth + 'mm.'"
                  :label="$t('Breedte (mm)')"
                  :max="maxWidth"
                  :min="minWidth"
                  :placeholder="$t('Vul een breedte in')"
                  :validation="'required|number|between:' + (minWidth - 1) + ',' + (maxWidth + 1) + ',value'"
                  :validationMessages="{ between: $t('Breedte moet tussen ' + minWidth + ' en ' + maxWidth) }"
                  type="number"
                  @input="setWidth"
                ></formulate-input>

                <formulate-input
                  v-model="selectedOptions.height"
                  :help="maxHeight > 0 ? $t('De maximale hoogte is ') + maxHeight + 'mm.' : ''"
                  :label="$t('Hoogte (mm)')"
                  :max="maxHeight"
                  :placeholder="$t('Vul een hoogte in')"
                  :validation="'required|number|max:' + (maxHeight)"
                  :validationMessages="{
                    max: maxHeight > 0 ? $t('Hoogte (mm) moet kleiner of gelijk zijn aan ' + maxHeight) : '',
               }"
                  type="number"
                  @input="fetchPrice"
                ></formulate-input>

                <div class="buttons">
                  <button :disabled="!(validateWidth())" class="btn" @click="setStep('fabric_types')">{{ $t('Volgende') }}</button>
                </div>
              </formulate-form>
            </div>

            <div v-if="activeStep === 'fabric_types'">
              <h2>{{ $t('Selecteer de doeksoort') }}</h2>

              <formulate-input type="select" :options="fabricTypes" v-model="selectedOptions.fabric_type"/>

              <div class="buttons">
                <button :disabled="!(validateFabricType())" class="btn" @click="setStep('cabinet_colors')">{{ $t('Volgende') }}</button>
              </div>
            </div>

            <div v-if="activeStep === 'cabinet_colors'">
              <h2>{{ $t('Selecteer de kastkleur') }}</h2>

              <!--              <formulate-input v-model="selectedOptions.color_alternative" :label="$t('Alternatieve RAL-kleur')" type="checkbox" @change="setAlternativeColor"></formulate-input>-->

              <!--              <div class="colors">-->
              <!--                <div v-for="color in cabinetColors" :key="'cabinet-' + color.ral" :class="{ 'active' : selectedOptions.color && selectedOptions.color.ral === color.ral }" class="color-wrapper" @click="selectColor(color)" @dblclick="setStep('fabric_colors')">-->
              <!--                  <Icon v-if="selectedOptions.color && selectedOptions.color.ral === color.ral" class="check" color="#79B0EA" icon="ic:round-check-circle" width="22"/>-->
              <!--                  <div :class="{ 'active' : selectedOptions.color && selectedOptions.color.ral === color.ral }" :style="{ 'background-color': ralColors[color.ral].color.hex }" class="color"></div>-->
              <!--                  <span>{{ color.name }}</span>-->
              <!--                </div>-->
              <!--              </div>-->

              <formulate-input v-model="ralSearch" :value="selectedOptions.color && selectedOptions.color.ral" :placeholder="$t('Ral kleur zoeken')" class="color-search" name="search"></formulate-input>

              <div class="colors">
                <div v-for="color in ralColorsSorted" :key="color.code" class="color-wrapper" @click="selectColor(color)" @dblclick="setStep('fabric')">
                  <Icon v-if="selectedOptions.color && selectedOptions.color.ral === color.code" class="check" color="#79B0EA" icon="ic:round-check-circle" width="22"/>
                  <div :class="{ 'active' : selectedOptions.color && selectedOptions.color.ral === color.code }" :style="{ 'background-color': color.color.hex }" class="color"></div>
                  <span>RAL{{ color.code }}</span>
                </div>
              </div>

              <div class="buttons">
                <button :disabled="!(validateColors())" class="btn" @click="setStep('fabric_colors')">{{ $t('Volgende') }}</button>
              </div>
            </div>

            <div v-if="activeStep === 'fabric_colors'">
              <h2>{{ $t('Selecteer de kleur van het doek') }}</h2>

              <div class="colors">
                <div v-for="color in fabricColors" :key="'fabric-' + color.id" :class="{ 'active' : selectedOptions.fabric_color && selectedOptions.fabric_color.name === color.name }" class="color-wrapper" @click="selectFabricColor(color)" @dblclick="setStep('options')">
                  <Icon v-if="selectedOptions.fabric_color && selectedOptions.fabric_color.name === color.name" class="check" color="#79B0EA" icon="ic:round-check-circle" width="22"/>
                  <div :class="{ 'active' : selectedOptions.fabric_color === color.name }" :style="{ 'background-color': '#EEEEEE'}" class="color">
                    <img v-if="fabricImages[color.name].img" :src="fabricImages[color.name].img" alt="">
                  </div>
                  <!--                  <div :class="{ 'active' : selectedOptions.fabric_color && selectedOptions.fabric_color.name === color.name }" :style="{ 'background-color': ralColors[color.ral] && ralColors[color.ral].color.hex }" class="color"></div>-->
                  <span>{{ color.name }}</span>
                </div>
              </div>

              <div class="buttons">
                <button :disabled="!(validateFabricColor())" class="btn" @click="setStep('options')">{{ $t('Volgende') }}</button>
              </div>
            </div>

            <div v-if="activeStep === 'options'">
              <h2>{{ $t('Opties') }}</h2>
              <div class="help">{{ $t('Selecteer de opties die je wilt toevoegen') }}</div>
              <v-select ref="tester" v-model="selectedOptions.optionsSelected" :options="options" label="name" multiple @option:selecting="selectOption" @option:deselected="deselectOption"></v-select>

              <div class="option-inputs">
                <div v-for="option in selectedOptions.options" :key="option.id">
                  <div class="icon" @click="deselectOption(option)">
                    <Icon color="#cf5353" icon="clarity:remove-solid"/>
                  </div>
                  <formulate-input v-model="option.amount" :label="$t(option.name)" class="with-suffix" type="number">
                    <template #suffix>
                      <span class="suffix">{{ option.unit_type === 'piece' ? $t('aantal stuks') : $t('aantal lopende meter') }}</span>
                    </template>
                  </formulate-input>
                </div>
              </div>

              <div class="buttons">
                <button class="btn" @click="backToOverview">{{ $t('Configuratie opslaan en terugkeren naar offerte') }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CalculatorButton from '@/components/world/CalculatorButton'
import { Icon } from '@iconify/vue2'
import { ApiService } from '@/services/admin/api.service'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import { DateTime } from 'luxon'
import calculators from '@/calculators'

export default {
  name: 'Calculator',
  mixins: [
    calculators
  ],
  components: {
    Icon,
    CalculatorButton,
    vSelect
  },
  data () {
    return {
      price: null,
      quoteNumber: null,
      calculatorData: null,
      activeStep: 'name',
      cabinetColors: [],
      fabricColors: [],
      minWidth: null,
      maxWidth: null,
      maxHeight: null,
      types: [
        {
          key: 'zipx85',
          label: 'ZipX85',
          description: '',
          image: require('@/assets/img/calculator/types/zipx85.webp')
        },
        {
          key: 'zipx95',
          label: 'ZipX95',
          description: '',
          image: require('@/assets/img/calculator/types/zipx85.webp')
        },
        {
          key: 'zipx120',
          label: 'ZipX120',
          description: '',
          image: require('@/assets/img/calculator/types/zipx85.webp')
        }
      ],
      engineOptions: [],
      options: [],
      connections: {},
      enginesConnections: {},
      selectedOptions: {
        name: '',
        measurement_style: '',
        engineConnection: null,
        type: '',
        engine: null,
        color: null,
        fabric_color: null,
        fabric_type: 'serge_5',
        color_alternative: false,
        options: [],
        optionsData: [],
        optionsSelected: []
      },
      datetime: DateTime,
      ralSearch: ''
    }
  },
  async mounted () {
    await this.setDealer()
    await this.fetchQuoteNumber()
    await this.fetchScreen()

    if (this.selectedOptions.type) {
      await this.selectType(this.selectedOptions.type, false)
    }

    if (this.selectedOptions.engine) {
      await this.setEngine()
    }

    if (this.selectedOptions.engine && this.selectedOptions.connection && this.selectedOptions.width && this.selectedOptions.height) {
      await this.fetchPrice()
    }

    if (this.activeStep === 'name') {
      this.$refs.name.$el.querySelector('input').focus()
    }
  },
  methods: {
    async fetchQuoteNumber () {
      await ApiService.fetchQuoteNumber(this.$route.params.quoteId).then(res => {
        this.quoteNumber = res.data
      })
    },
    async fetchScreen () {
      if (this.$route.params.quoteId && this.$route.params.id) {
        await ApiService.fetchScreen(this.$route.params.id).then(async res => {
          this.calculatorData = res.data

          if (res.status === 200) {
            await this.normalizeCalculatorData(this.calculatorData)
          }
        })
      }
    },
    async fetchPrice () {
      await ApiService.fetchScreenPrice(this.selectedOptions.type, this.selectedOptions.engine, this.selectedOptions.connection, this.selectedOptions.width, this.selectedOptions.height, this.selectedOptions.measurement_style).then(res => {
        this.price = res.data
      })

      const res = await ApiService.fetchFabricTypes(this.selectedOptions.type, this.selectedOptions.engine, this.selectedOptions.connection, this.selectedOptions.width, this.selectedOptions.height)
      this.fabricTypes = {}

      if (res.data) {
        for (const fabricType of res.data.sort((a, b) => a > b ? 1 : -1)) {
          this.fabricTypes[fabricType] = this.$t(fabricType)
        }
      }
    },
    backToOverview () {
      this.setStep(this.activeStep)
      this.$router.push({
        name: 'ww.quotes.detail',
        params: { id: this.$route.params.quoteId },
        query: { tab: 'overview' }
      })
    },
    async normalizeCalculatorData (data) {
      this.selectedOptions = { ...this.selectedOptions, ...data }
      this.selectedOptions.optionsSelected = []

      for (const option of this.selectedOptions.options) {
        const foundOption = this.options.find(optionObj => {
          if (optionObj.internal_name === option.name) {
            return optionObj
          }
        })

        if (foundOption) {
          this.selectedOptions.optionsSelected.push(foundOption)
        }
      }

      if (this.selectedOptions.engine && this.selectedOptions.connection) {
        this.selectedOptions.engineConnection = this.selectedOptions.engine + '_' + this.selectedOptions.connection
      }

      this.selectedOptions.color = data.colors.find(color => color.component === 'cabinet')

      // if (this.selectedOptions.color && !this.selectedOptions.color.ral) {
      //   this.selectedOptions.color_alternative = true
      // }

      this.selectedOptions.fabric_color = data.colors.find(color => color.component === 'fabric')
    },
    /** validatons methods **/
    validateName () {
      if (this.selectedOptions.name && this.selectedOptions.name !== '') {
        return true
      }

      return false
    },
    validateType () {
      if (this.validateName() && this.selectedOptions.type) {
        return true
      }

      return false
    },
    // validateControls () {
    //   if (this.validateType() && this.selectedOptions.connection) {
    //     return true
    //   }
    //
    //   return false
    // },
    validateMotor () {
      if (this.validateType() && this.selectedOptions.engine) {
        return true
      }

      return false
    },
    validateWidth () {
      if (this.validateMotor() && this.selectedOptions.width >= this.minWidth && this.selectedOptions.width <= this.maxWidth && this.selectedOptions.height && this.selectedOptions.height <= this.maxHeight && this.price) {
        return true
      }

      return false
    },
    validateFabricType () {
      if (this.validateWidth() && this.selectedOptions.fabric_type) {
        return true
      }

      return false
    },
    validateColors () {
      if (this.validateFabricType() && this.selectedOptions.color) {
        return true
      }

      return false
    },
    validateFabricColor () {
      if (this.validateColors() && this.selectedOptions.fabric_color) {
        return true
      }

      return false
    },
    async setStep (step) {
      this.calculatorData = { ...this.calculatorData, ...this.selectedOptions }
      this.calculatorData.width = parseInt(this.calculatorData.width)

      if (this.activeStep === 'options' && this.calculatorData.options.length > 0) {
        for (const option of this.calculatorData.options) {
          await ApiService.patchScreenOption(this.calculatorData.id, option.id, option)
        }
      }

      if (this.activeStep === 'motor' && this.validateMotor()) {
        await this.setEngine()
      }

      delete this.calculatorData.added_height
      delete this.calculatorData.added_width
      delete this.calculatorData.shortened_engine

      await ApiService.patchScreen(this.calculatorData.id, this.calculatorData).then(async res => {
        await this.normalizeCalculatorData(res.data)
      })

      this.activeStep = step
    },
    async selectType (type, resetEngineConnection = true) {
      this.selectedOptions.type = type

      if (resetEngineConnection) {
        this.selectedOptions.engine = null
        this.selectedOptions.connection = null
        this.selectedOptions.engineConnection = null

        await this.deleteSelectedColor()
        await this.deleteSelectedFabricColor()
      }

      await this.fetchCalculatorEnginesConnections()
      await this.setWidth()
    },
    async fetchCalculatorEnginesConnections () {
      await ApiService.fetchScreenEnginesConnections(this.selectedOptions.type).then(async res => {
        this.enginesConnections = {}

        res.data.forEach(engineConnection => {
          this.enginesConnections[engineConnection.engine + '_' + engineConnection.connection] = this.$t(engineConnection.engine) + ' - ' + this.$t(engineConnection.connection)
        })

        if (Object.keys(this.enginesConnections).length === 1) {
          this.selectedOptions.engineConnection = Object.keys(this.enginesConnections)[0]
          await this.setEngine()
        }
      })
    },
    async setEngine () {
      if (this.selectedOptions.engineConnection) {
        const engineConnection = this.selectedOptions.engineConnection.split('_')
        this.selectedOptions.engine = engineConnection[0]
        this.selectedOptions.connection = engineConnection[1]
      }

      await this.fetchCalculatorInfo()
      await ApiService.fetchScreenOptions(this.selectedOptions.type).then(res => {
        this.options = res.data.map(option => {
          option.internal_name = option.name
          option.name = this.$t(option.name)
          return option
        }).sort((a, b) => a.name > b.name ? 1 : -1)
      })
    },
    async deleteSelectedColor () {
      if (this.selectedOptions.color) {
        await ApiService.deleteScreenColor(this.$route.params.id, this.selectedOptions.color.id)
        this.selectedOptions.color = null
      }
    },
    async deleteSelectedFabricColor () {
      if (this.selectedOptions.fabric_color) {
        await ApiService.deleteScreenColor(this.$route.params.id, this.selectedOptions.fabric_color.id)
        this.selectedOptions.fabric_color = null
      }
    },
    async setAlternativeColor () {
      await this.deleteSelectedColor()

      await ApiService.createScreenColor(this.$route.params.id, { component: 'cabinet' }).then(res => {
        if (res.status === 200) {
          this.selectedOptions.color = res.data
        }
      })
    },
    async selectColor (color) {
      await this.deleteSelectedColor()
      this.selectedOptions.color_alternative = false

      await ApiService.createScreenColor(this.$route.params.id, {
        ral: color.code,
        component: 'cabinet'
      }).then(res => {
        if (res.status === 200) {
          this.selectedOptions.color = res.data
        }
      })
    },
    async selectFabricColor (color) {
      await this.deleteSelectedFabricColor()

      await ApiService.createScreenColor(this.$route.params.id, {
        color_id: color.id,
        component: color.component
      }).then(res => {
        if (res.status === 200) {
          this.selectedOptions.fabric_color = res.data
        }
      })
    },
    async fetchCalculatorInfo () {
      await ApiService.fetchScreenInfo(this.selectedOptions.type, this.selectedOptions.engine).then((res) => {
        if (res.data) {
          this.minWidth = res.data.width[0]
          this.maxWidth = res.data.width[1]
          this.cabinetColors = res.data.colors.cabinet
          this.fabricColors = res.data.colors.fabric
        }
      })
    },
    async setWidth () {
      if (this.validateMotor() && this.selectedOptions.width) {
        await ApiService.fetchScreenHeight(this.selectedOptions.type, this.selectedOptions.engine, this.selectedOptions.connection, this.selectedOptions.width, this.selectedOptions.measurement_style).then(res => {
          this.maxHeight = res.data || 0
        })
      }
    },
    /** options methods **/
    async selectOption (option) {
      // await this.setStep(this.activeStep)

      const foundOption = this.selectedOptions.options.find(optionObj => optionObj.internal_name === option.internal_name)

      if (!foundOption) {
        const obj = {
          option_id: option.id,
          amount: 1
        }

        await ApiService.createScreenOption(this.calculatorData.id, obj)

        await this.fetchScreen()
      }
    },
    async selectSubOption (internalName) {
      const option = this.findOptionByInternalName(internalName)
      await ApiService.createScreenOption(this.calculatorData.id, option)
      await this.fetchScreen()
      this.optionHomemotion = false
    },
    async deselectOption (option) {
      // delete option based on input options
      let quoteOption = option

      if (!Object.keys(option).includes('calculated_total')) {
        // delete option based on select data
        quoteOption = this.selectedOptions.options.find(opt => option.internal_name === opt.name)
      }

      if (quoteOption) {
        await ApiService.deleteScreenOption(this.calculatorData.id, quoteOption.id)
        await this.fetchScreen()
      }
    },
    findOptionByInternalName (internalName) {
      const foundOption = this.options.find(optionObj => optionObj.internal_name === internalName)

      if (foundOption) {
        const existingOption = this.selectedOptions.options.find(optionObj => optionObj.option_id === foundOption.id)

        if (!existingOption) {
          return {
            option_id: foundOption.id,
            amount: 0
          }
        }
      }

      return null
    }
  },
  computed: {
    fabricImages () {
      const imagesMap = []
      for (const fabricColor of this.fabricColorsScreens) {
        imagesMap[fabricColor.name] = fabricColor
      }
      return imagesMap
    },
    ralColorsSorted () {
      if (this.ralSearch === '') {
        return []
      }

      const filteredRals = {}
      Object.keys(this.ralColors).forEach(ral => {
        if (ral.includes(this.ralSearch) || ('RAL' + ral).includes(this.ralSearch)) {
          filteredRals[ral] = this.ralColors[ral]
        }
      })
      return filteredRals
    }
  }
}
</script>
